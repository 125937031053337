module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-NFMGF6JF"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 375px)","sm":"(max-width: 768px)","md":"(max-width: 1024px)","l":"(max-width: 1536px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"2meters.app","short_name":"2meters.app","start_url":"/","icon":"src/images/logo.png","icons":[{"src":"src/favicons/icon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/favicons/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/favicons/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"src/favicons/safari-pinned-tab.svg","sizes":"512x512","type":"image/svg+xml"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c2ac403531d71b20df020196f05bd1b7"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
