exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-design-system-tsx": () => import("./../../../src/pages/design-system.tsx" /* webpackChunkName: "component---src-pages-design-system-tsx" */),
  "component---src-pages-prices-tsx": () => import("./../../../src/pages/prices.tsx" /* webpackChunkName: "component---src-pages-prices-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-templates-contacts-page-contacts-page-tsx": () => import("./../../../src/templates/ContactsPage/ContactsPage.tsx" /* webpackChunkName: "component---src-templates-contacts-page-contacts-page-tsx" */),
  "component---src-templates-feature-page-feature-page-tsx": () => import("./../../../src/templates/FeaturePage/FeaturePage.tsx" /* webpackChunkName: "component---src-templates-feature-page-feature-page-tsx" */),
  "component---src-templates-features-page-features-page-tsx": () => import("./../../../src/templates/FeaturesPage/FeaturesPage.tsx" /* webpackChunkName: "component---src-templates-features-page-features-page-tsx" */),
  "component---src-templates-home-page-home-page-tsx": () => import("./../../../src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-home-page-tsx" */),
  "component---src-templates-imprint-page-imprint-page-tsx": () => import("./../../../src/templates/ImprintPage/ImprintPage.tsx" /* webpackChunkName: "component---src-templates-imprint-page-imprint-page-tsx" */),
  "component---src-templates-price-page-price-page-tsx": () => import("./../../../src/templates/PricePage/PricePage.tsx" /* webpackChunkName: "component---src-templates-price-page-price-page-tsx" */),
  "component---src-templates-privacy-policy-privacy-policy-tsx": () => import("./../../../src/templates/PrivacyPolicy/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-privacy-policy-tsx" */),
  "component---src-templates-product-page-product-page-tsx": () => import("./../../../src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-product-page-tsx" */),
  "component---src-templates-products-page-products-page-tsx": () => import("./../../../src/templates/ProductsPage/ProductsPage.tsx" /* webpackChunkName: "component---src-templates-products-page-products-page-tsx" */),
  "component---src-templates-terms-conditions-terms-conditions-tsx": () => import("./../../../src/templates/TermsConditions/TermsConditions.tsx" /* webpackChunkName: "component---src-templates-terms-conditions-terms-conditions-tsx" */)
}

